<script setup>
import googleOneTap from 'google-one-tap';
import route from "ziggy-js";
import {useForm, usePage} from "@inertiajs/vue3";

const form = useForm({
  credential: ''
});
const options = {
  client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
  auto_select: false,
  cancel_on_tap_outside: true
};
const page = usePage()
const isAuthenticated = page.props.auth?.user ?? false
if (!isAuthenticated) {
  googleOneTap(options, (response) => {
    // Send response to server
    form.credential = response.credential;
    form.post(route('oauth.callback', {provider: 'google'}));
  });
}
</script>

<template>

</template>
